<template>
  <div>
    <div class="mb-4">
      <product-selector-dialog v-if="list.length < 5" :status="status" @added="addProduct"/>
      <v-btn v-else disabled small>Maxim 5 produse</v-btn>
    </div>
    <div>
      <draggable
        :list="list"
        class="list-group cursor-move"
        ghost-class="ghost"
        @end="dragging = false"
        @start="dragging = true"
      >
        <div v-for="(item, index) in list" :key="index" class="mb-2">
          <v-chip
            close
            close-icon="mdi-delete"
            @click:close="deleteItem(index)"
          >{{ item.product_id }}: {{ item.full_name }}
          </v-chip>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ProductSelectorDialog from '@/views/Settings/HomepageSettings/components/mostPopular/productSelectorDialog'

export default {
  name: 'productList',
  components: {
    ProductSelectorDialog,
    draggable
  },
  props: {
    value: {},
    status: {
      default: false
    }
  },
  methods: {
    addProduct (productObj) {
      this.list.push(productObj)
    },
    deleteItem (itemIndex) {
      this.list.splice(itemIndex, 1)
    }
  },
  computed: {
    list: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
