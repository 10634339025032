<template>
  <v-sheet>
    <v-card>
      <v-card-text>

        <v-text-field v-model="form.title" dense label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>

        <v-text-field v-model="form.description" dense label="Description" outlined/>
        <error-messages :errors="errors.description"/>

        <v-text-field v-model="form.keywords" dense label="Keywords" outlined/>
        <error-messages :errors="errors.keywords"/>

      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="primary" class="primary-button" @click="saveSettings">Salvare</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages
  },
  data () {
    return {
      loading: false,
      errors: {},
      form: {
        title: '',
        description: '',
        keywords: ''
      }
    }
  },
  methods: {
    getForm () {
      this.$http.get('settings/homepage/seo')
        .then(({data}) => {
          this.form = data
        })
    },
    saveSettings () {
      this.errors = {}
      this.loading = true
      this.$http.post('settings/homepage/seo', this.form)
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})

        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getForm()
  }
}
</script>
