<template>
  <v-container class="max-w-full">
    <draggable
      :disabled="!enabled"
      :list="sections"
      class="list-group cursor-move"
      ghost-class="ghost"
      @end="dragging = false"
      @start="dragging = true"
    >
      <v-card v-for="(item, index) in sections" :key="index"
              class="mb-4">
        <v-container class="max-w-full">
          <v-row>
            <v-btn
              class="mx-2 ml-auto absolute right-0 z-10"
              color="red"
              icon
              @click="removeItem(index)"
            >
              <vs-icon icon="fa-times" icon-pack="fal"></vs-icon>
            </v-btn>
          </v-row>

          <v-radio-group v-model="item.isProduct">
            <template v-slot:label>
              <div class="font-weight-bold text-xl">Tipul slide-ului:</div>
            </template>
            <div class="flex items-center mt-3">
              <v-radio :value="1" checked class="mr-5 mb-0">
                <template v-slot:label>
                  <div>Produs</div>
                </template>
              </v-radio>
              <v-radio :value="0">
                <template v-slot:label>
                  <div>Text</div>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </v-container>

        <template v-if="item.isProduct === 1">
          <v-container class="max-w-full pt-0">
            <product-selector v-model="item.product"/>

            <v-row class="mt-5">
              <v-col>
                <div class="d-flex items-center">
                  <span class="mr-3 w-1/12">Imagine Background</span>
                  <v-select
                    v-model="item.background_image"
                    :items="background_types"
                    dense
                    hide-details
                    item-text="color"
                    item-value="background"
                    label="Imagine"
                    outlined
                  ></v-select>
                </div>
                <error-messages :errors="errors[`settings.${index}.background_image`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <v-container class="max-w-full pt-0">
            <v-row>
              <v-col cols="12">
                <div class="d-flex items-center">
                  <span class="mr-3 w-1/12">Titlu slide</span>
                  <v-text-field
                    v-model="item.slide_title"
                    class="mt-0"
                    dense
                    hide-details
                    label="Titlu slide"
                    outlined
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.title`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="d-flex items-center">
                  <span class="mr-3 w-2/12">Titlu produs</span>
                  <v-text-field
                    v-model="item.title"
                    class="mt-0"
                    dense
                    hide-details
                    label="Titlu"
                    outlined
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.title`]" class="mt-1"></error-messages>
              </v-col>

              <v-col cols="6">
                <div class="d-flex items-center">
                  <span class="mr-3 w-2/12">Subtitlu produs</span>
                  <v-text-field
                    v-model="item.subtitle"
                    class="mt-0"
                    dense
                    hide-details
                    label="Subtitlu"
                    outlined
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.subtitle`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="d-flex items-center">
                  <span class="mr-3 w-2/12">Pret Vechi</span>
                  <v-text-field
                    v-model="item.old_price"
                    class="mt-0"
                    dense
                    hide-details
                    label="Pret Vechi"
                    outlined
                    type="number"
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.old_price`]" class="mt-1"></error-messages>
              </v-col>
              <v-col cols="6">
                <div class="d-flex items-center">
                  <span class="mr-3 w-2/12">Pret nou</span>
                  <v-text-field
                    v-model="item.price"
                    class="mt-0"
                    dense
                    hide-details
                    label="Pret nou"
                    outlined
                    type="number"
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.price`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="d-flex items-center">
                  <span class="mr-3 w-2/12">Buton Text</span>
                  <v-text-field
                    v-model="item.cta_label"
                    class="mt-0"
                    dense
                    hide-details
                    label="Buton Text"
                    outlined
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.cta_label`]" class="mt-1"></error-messages>
              </v-col>
              <v-col cols="6">
                <div class="d-flex items-center">
                  <span class="mr-3 w-2/12">Slide Link</span>
                  <v-text-field
                    v-model="item.cta_link"
                    class="mt-0"
                    dense
                    hide-details
                    label="Buton Link"
                    outlined
                  ></v-text-field>
                </div>
                <error-messages :errors="errors[`settings.${index}.cta_link`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex items-center">
                  <span class="mr-3">Imagine Produs</span>
                </div>
                <div class="d-flex items-center" v-if="item.product_image_obj">
                  <img :src="item.product_image_obj.src" alt="" width="200"/>
                </div>
                <image-uploader v-model="item.product_image"/>
                <error-messages :errors="errors[`settings.${index}.product_image`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex items-center">
                  <span class="mr-3">Imagine Background</span>
                </div>
                <div class="d-flex items-center" v-if="item.background_image_obj">
                  <img :src="item.background_image_obj.src" alt="" width="200"/>
                </div>
                <image-uploader v-model="item.background_image"/>
                <error-messages :errors="errors[`settings.${index}.background_image`]" class="mt-1"></error-messages>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card>

    </draggable>

    <v-row>
      <v-col class="pt-5 justify-end">
        <v-btn depressed large
               class="mr-3"
               @click="addItem">
          Adauga sectiune noua
        </v-btn>
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import ImageUploader from '@/components/general-form/ImageUploader'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductList from '@/views/Settings/HomepageSettings/components/mostPopular/productList'
import ProductSelector from '@/components/autocompletes/ProductSelector'

export default {
  name: 'banner',
  components: {ProductSelector, ProductList, ErrorMessages, ImageUploader, draggable},
  data () {
    return {
      enabled: true,
      dragging: false,
      sections: [],
      errors: {},
      background_types: [
        {color: 'Verde', background: 'background-header-verde'},
        {color: 'Galben', background: 'background-header-galben'},
        {color: 'Albastru', background: 'background-header-albastru'},
        {color: 'Rosu', background: 'background-header-rosu'}
      ]
    }
  },
  methods: {
    addItem () {
      const sectionData = {
        isProduct: 1,
        product: '',
        slide_title: '',
        background_image: '',
        product_image: '',
        cta_colors: '',
        cta_label: '',
        cta_link: '',
        subtitle: '',
        title: ''
      }
      this.sections.push(sectionData)
    },
    removeItem (item) {
      this.sections.splice(item, 1)
    },
    getBannerSettings () {
      this.$http.get('settings/homepage/banner')
        .then(({data}) => {
          this.sections = data
        })
    },
    saveSettings () {
      this.$http.post('settings/homepage/banner', {
        settings: this.sections
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
    }
  },
  created () {
    this.getBannerSettings()
  }
}
</script>

<style scoped>

</style>
