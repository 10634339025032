<template>
  <v-tabs class="homepage-settings" left vertical>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Banner</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiuni principale</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">SEO</span>
    </v-tab>
    <!--    <v-tab class="justify-start pl-0">-->
    <!--      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>-->
    <!--      <span class="ml-2 font-weight-bold">Cele mai populare</span>-->
    <!--    </v-tab>-->

    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a target="_blank"  @click=" NavigationLink(`https://` + franchisee.domain)">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <banner />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <sub-banner-sections />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <seo />
      </v-card>
    </v-tab-item>
    <!--    <v-tab-item class="ml-5">-->
    <!--      <v-card elevation="3" rounded>-->
    <!--        <most-popular/>-->
    <!--      </v-card>-->
    <!--    </v-tab-item>-->
  </v-tabs>
</template>

<script>
import Banner from '@/views/Settings/HomepageSettings/components/banner'
import SubBannerSections from '@/views/Settings/HomepageSettings/components/subBannerSections'
import MostPopular from '@/views/Settings/HomepageSettings/components/mostPopular'
import ImportProducts from '@/views/Settings/HomepageSettings/components/import/importProducts'
import Seo from '@/views/Settings/HomepageSettings/components/seo'
export default {
  name: 'index',
  components: {
    Seo,
    ImportProducts,
    MostPopular,
    SubBannerSections,
    Banner
  },
  computed: {
    franchisee_id() {
      return this.$store.getters['auth/currentUser'].franchisee_id
    },
    franchisee() {
      return this.$store.getters['singleFranchisee/item']
    }
  },
  methods: {
    NavigationLink(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    loadFranchisee() {
      this.franchisee_id && this.$store.dispatch('singleFranchisee/load', this.franchisee_id)
    }
  },
  created() {
    this.loadFranchisee()
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
