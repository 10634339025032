<template>
  <v-container class="max-w-full pt-0">
    <v-row class="pt-2">
      <v-col>
        <h3>Banner</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="d-flex"
        cols="12"
      >
        <v-select
          :items="banners.data"
          label="Outlined style"
          item-text="title"
          item-value="id"
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5 justify-end">
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ImageUploader from '@/components/general-form/ImageUploader'

export default {
  name: 'importProductsBanner',
  components: {ErrorMessages, ImageUploader},
  data () {
    return {
      // banners: []
    }
  },
  computed: {
    banners () {
      return this.$store.getters['banners/paginatedData']
    }
  },
  methods: {
    saveSettings () {
      this.$http.post('settings/homepage/banner', {
        settings: this.sections
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch('banners/loadItems')
  }
}
</script>

<style scoped>

</style>
