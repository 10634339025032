<template>
  <div>
    <featured-product />
    <v-divider />
    <import-products-banner />
  </div>
</template>

<script>
import FeaturedProduct from '@/views/Settings/HomepageSettings/components/import/featuredProduct'
import ImportProductsBanner from '@/views/Settings/HomepageSettings/components/import/importProductsBanner'
export default {
  name: 'importProducts',
  components: {ImportProductsBanner, FeaturedProduct}
}
</script>

<style scoped>

</style>
