<template>
  <v-container class="max-w-full">
    <draggable :disabled="!enabled" :list="sections" class="list-group cursor-move" ghost-class="ghost"
      @end="dragging = false" @start="dragging = true">
      <v-card v-for="(item, index) in sections" :key="index" class="mb-4">
        <v-container class="max-w-full">
          <v-row>
            <v-btn class="mx-2 ml-auto" color="red" icon @click="removeItem(index)">
              <vs-icon icon="fa-times" icon-pack="fal"></vs-icon>
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="d-flex items-center">
                <span class="mr-3 w-2/12">Titlu</span>
                <v-text-field v-model="item.title" class="mt-0" dense hide-details label="Titlu" outlined></v-text-field>
              </div>
              <error-messages :errors="errors[`settings.${index}.title`]" class="mt-1"></error-messages>
            </v-col>

            <v-col cols="6">
              <div class="d-flex items-center">
                <span class="mr-3 w-2/12">Subtitlu</span>
                <v-text-field v-model="item.subtitle" class="mt-0" dense hide-details label="Subtitlu"
                  outlined></v-text-field>
              </div>
              <error-messages :errors="errors[`settings.${index}.subtitle`]" class="mt-1"></error-messages>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="d-flex items-center">
                <span class="mr-3 w-2/12">Buton Text</span>
                <v-text-field v-model="item.cta_label" class="mt-0" dense hide-details label="Buton Text"
                  outlined></v-text-field>
              </div>
              <error-messages :errors="errors[`settings.${index}.cta_label`]" class="mt-1"></error-messages>
            </v-col>
            <v-col cols="6">
              <div class="d-flex items-center">
                <span class="mr-3 w-2/12">Buton Link</span>
                <v-text-field v-model="item.cta_link" class="mt-0" dense hide-details label="Buton Link"
                  outlined></v-text-field>
              </div>
              <error-messages :errors="errors[`settings.${index}.cta_link`]" class="mt-1"></error-messages>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex items-center">
                <span class="mr-3 w-1/12">Culoare</span>
                <v-select v-model="item.cta_colors" :items="color_items" dense hide-details item-text="color"
                  item-value="class" label="Culoare" outlined></v-select>
              </div>
              <error-messages :errors="errors[`settings.${index}.cta_colors`]" class="mt-1"></error-messages>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex items-center">
                <span class="mr-3 w-1/12">Imagine</span>
                <img :src="item && item.image_object && item.image_object.src" alt="" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex items-center">
                <span class="mr-3">Imagine Background</span>
              </div>
              <image-uploader v-model="item.background_image" />
              <error-messages :errors="errors[`settings.${index}.background_image`]" class="mt-1"></error-messages>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

    </draggable>

    <v-row class="text-right">
      <v-col class="pt-5 justify-end">
        <v-btn depressed @click="addItem">
          Adauga sectiune noua
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" dark large @click="saveSettings">
          Salveaza
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import ImageUploader from '@/components/general-form/ImageUploader'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'subBannerSections',
  components: {
    ErrorMessages,
    draggable,
    ImageUploader
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      sections: [],
      errors: {},
      color_items: [
        { color: 'Verde', class: 'text-green-400 hover:bg-green-400 hover:text-white' },
        { color: 'Galben', class: 'text-yellow-400 hover:bg-yellow-400 hover:text-white' },
        { color: 'Rosu', class: 'text-red-400 hover:bg-red-400 hover:text-white' },
        { color: 'Albastru', class: 'text-blue-400 hover:bg-blue-400 hover:text-white' },
        { color: 'Gri', class: 'text-grey-400 hover:bg-grey-400 hover:text-white' }
      ]
    }
  },
  methods: {
    addItem() {
      const sectionData = {
        background_image: '',
        cta_colors: '',
        cta_label: '',
        cta_link: '',
        subtitle: '',
        title: ''
      }
      this.sections.push(sectionData)
    },
    removeItem(item) {
      this.sections.splice(item, 1)
    },
    getSubBannerSettings() {
      this.$http.get('settings/homepage/sub-banner-section')
        .then(({ data }) => {
          this.sections = data
        })
    },
    saveSettings() {
      this.$http.post('settings/homepage/sub-banner-section', {
        settings: this.sections
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, { root: true })
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, { root: true })
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, { root: true })
          }
        })
    }
  },
  created() {
    this.getSubBannerSettings()
  }
}
</script>

<style lang="scss" scoped>
.v-input--is-label-active input {
  margin-top: 0;
}
</style>
